import permissions from 'config/permissions';
import Can from 'containers/Can';
import { Router } from 'next/router';
import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import COOKIES from 'shared/constants/COOKIES';
import LOGO from 'shared/constants/LOGO_SOURCES';
import SIDEBAR_ICON from 'shared/constants/SIDEBAR_ICONS';
import { canView } from 'shared/helpers/permissions';
import routes, { routesPatterns } from 'shared/helpers/routes';
import { setCookie, getCookie } from 'shared/lib/cookie';
import UserIcon from '../../../static/icons/user-white.svg';
import CompanySidebarLink from '../CompanySidebarLink';
import Logo from '../Logo';
import UserName from '../UserName';
import SidebarInvoices from './SidebarInvoices';
import SidebarLink from './SidebarLink';
import SidebarSales from './SidebarSales';
import Styled from './styles';

interface SidebarProps {
  data: any;
  router: Router;
  toggleSidebarState: () => void;
  sidebarIsOpen: boolean;
  permissions?: {
    [key: string]: string;
  };
  isOwner: boolean;
  t: (value: string, params?: any) => string;
}

class Sidebar extends Component<SidebarProps> {
  componentDidUpdate(prevProps) {
    if (prevProps.sidebarIsOpen !== this.props.sidebarIsOpen) {
      setCookie(COOKIES.SIDEBAR_IS_OPEN, `${this.props.sidebarIsOpen}`);
    }
  }

  handleClick = () => {
    const defaultDates = '{"gte": "", "lte": ""}';
    const reportsDates = JSON.parse(getCookie('reportsDates') || defaultDates);
    if (reportsDates.gte.length) {
      setCookie(COOKIES.REPORTS_DATES, defaultDates);
    }
    if (window.innerWidth <= BREAKPOINTS.XL_NUMBER) {
      this.props.toggleSidebarState();
    }
  };

  render() {
    const { handleClick } = this;
    const {
      router: { asPath },
      data,
      toggleSidebarState: handleToggleSidebarState,
      sidebarIsOpen,
      permissions: currentPermission,
      isOwner,
      t,
    } = this.props;
    let membersLink = `${routes.membersUsers}?only_active=true`;

    if (
      !isOwner &&
      !canView(currentPermission, permissions.companyUser) &&
      canView(currentPermission, permissions.companyManager)
    ) {
      membersLink = routes.membersCompanies;
    }

    return (
      <ThemeProvider theme={{ sidebarIsOpen }}>
        <Styled.Container>
          <Styled.Wrapper>
            <Styled.Top>
              <a href={routes.root}>
                <Styled.Logo>
                  <Logo src={LOGO.WITH_NAME} />
                </Styled.Logo>
              </a>
              <Styled.UserMobileInfo href={routes.profile}>
                <Styled.UserInfo>
                  <UserName withoutLink data={data} />
                </Styled.UserInfo>
                <Styled.MobileNav>
                  <UserIcon />
                </Styled.MobileNav>
              </Styled.UserMobileInfo>
            </Styled.Top>
            <Styled.Nav>
              <Can
                permission={[
                  permissions.companyEditAnnouncement,
                  permissions.companyViewAnnouncement,
                  permissions.companyAccessToOrder,
                  permissions.companyAccessToPreOrder,
                ]}
              >
                <Styled.Menu>
                  <Styled.MenuTitle>{t('title.announcements')}</Styled.MenuTitle>
                  <Can
                    permission={[permissions.companyEditAnnouncement, permissions.companyViewAnnouncement]}
                  >
                    <SidebarLink
                      title={t('section.announcements')}
                      icon={SIDEBAR_ICON.ADS}
                      href={routes.announcements}
                      pathname={asPath}
                      onClick={handleClick}
                      dataSelenium="MyAds"
                    />
                  </Can>
                  <Can permission={permissions.companyAccessToOrder}>
                    <SidebarLink
                      title={t('section.purchases')}
                      icon={SIDEBAR_ICON.BUY}
                      href={routes.purchases}
                      pathname={asPath}
                      onClick={handleClick}
                      dataSelenium="MyBuy"
                    />
                  </Can>
                  <Can permission={permissions.companyAccessToOrder}>
                    <SidebarSales
                      title={t('section.sales')}
                      icon={SIDEBAR_ICON.SELL}
                      href={routes.sales}
                      pathname={asPath}
                      onClick={handleClick}
                      dataSelenium="MySell"
                    />
                  </Can>
                  <Can permission={permissions.companyAccessToPreOrder}>
                    <SidebarLink
                      title={t('section.preorders')}
                      icon={SIDEBAR_ICON.PREORDERS}
                      href={routes.preorders}
                      pathname={asPath}
                      onClick={handleClick}
                    />
                  </Can>
                  <Can permission={permissions.accessToInvoices}>
                    <SidebarInvoices
                      title={t('section.invoices')}
                      icon={SIDEBAR_ICON.CALLS}
                      href={routes.invoices}
                      pathname={asPath}
                      onClick={handleClick}
                    />
                  </Can>
                </Styled.Menu>
              </Can>
              <Can permission={permissions.pool}>
                <Styled.Menu data-selenium="ListAds">
                  <Styled.MenuTitle>{t('title.pool')}</Styled.MenuTitle>
                  <SidebarLink
                    title={t('section.pool')}
                    icon={SIDEBAR_ICON.TICKETS_POOL}
                    href={routes.pool}
                    pathname={asPath}
                    onClick={handleClick}
                  />
                </Styled.Menu>
              </Can>
              <Can permission={permissions.event}>
                <Styled.Menu>
                  <Styled.MenuTitle>{t('title.events')}</Styled.MenuTitle>
                  <SidebarLink
                    title={t('section.events')}
                    icon={SIDEBAR_ICON.EVENTS}
                    href={routes.events}
                    pathname={asPath}
                    onClick={handleClick}
                    dataSelenium="sidebar-events"
                  />
                  <Can permission={permissions.reference}>
                    <SidebarLink
                      title={t('section.bundles')}
                      icon={SIDEBAR_ICON.BUNDLES}
                      href={routes.bundlesActive}
                      pathname={asPath}
                      onClick={handleClick}
                      dataSelenium="sidebar-bundles"
                    />
                  </Can>
                </Styled.Menu>
              </Can>
              <Can permission={permissions.reference}>
                <Styled.Menu>
                  <Styled.MenuTitle>{t('title.settings')}</Styled.MenuTitle>
                  <SidebarLink
                    title={t('section.references')}
                    icon={SIDEBAR_ICON.REFERENCE}
                    as={routes.referencePlaces}
                    href={routesPatterns.reference}
                    root="/reference"
                    pathname={asPath}
                    onClick={handleClick}
                    dataSelenium="Reference"
                  />
                </Styled.Menu>
              </Can>
              <Can
                permission={[
                  permissions.companyUpdateInfo,
                  permissions.companyInviteUser,
                  permissions.companyContactInfo,
                  permissions.companyProvider,
                  permissions.companyPayments,
                  permissions.companyUserAdmin,
                  permissions.landings,
                  permissions.widget,
                  permissions.widgetEdit,
                  permissions.reportPurchase,
                  permissions.reportRefund,
                  permissions.reportSales,
                  permissions.reportOrder,
                  permissions.reportDelivery,
                  permissions.companyReportMarketplace,
                ]}
              >
                <Styled.Menu>
                  <Styled.MenuTitle>{t('title.company')}</Styled.MenuTitle>
                  <Can
                    permission={[
                      permissions.companyUpdateInfo,
                      permissions.companyInviteUser,
                      permissions.companyContactInfo,
                      permissions.companyProvider,
                      permissions.companyPayments,
                      permissions.companyUserAdmin,
                    ]}
                  >
                    <CompanySidebarLink
                      title={t('section.company')}
                      icon={SIDEBAR_ICON.COMPANY}
                      pathname={asPath}
                      onClick={handleClick}
                      dataSelenium="MyCompany"
                    />
                  </Can>
                  <Can permission={permissions.landings}>
                    <SidebarLink
                      title={t('section.landings')}
                      icon={SIDEBAR_ICON.LANDINGS}
                      href={routes.landingsSites}
                      root="/landings"
                      pathname={asPath}
                      onClick={handleClick}
                      dataSelenium="Landings"
                    />
                  </Can>
                  <Can permission={[permissions.event, permissions.reference]}>
                    <Styled.Menu>
                      <SidebarLink
                        title={t('section.market')}
                        icon={SIDEBAR_ICON.EVENTS}
                        href={routes.marketEvents}
                        pathname={asPath}
                        onClick={handleClick}
                        dataSelenium="sidebar-market"
                      />
                    </Styled.Menu>
                  </Can>

                  {/* <Can permission={[permissions.widget, permissions.widgetEdit]}>
                    <SidebarLink
                      title={t('section.widgets')}
                      icon={SIDEBAR_ICON.WIDGETS}
                      href={routes.widgets}
                      pathname={asPath}
                      onClick={handleClick}
                      dataSelenium="Widgets"
                    />
                  </Can> */}

                  <Can permission={[permissions.widgetEdit]}>
                    <SidebarLink
                      title={t('section.markup')}
                      icon={SIDEBAR_ICON.WIDGETS}
                      href={routes.markup}
                      pathname={asPath}
                      onClick={handleClick}
                      dataSelenium="Markup"
                    />
                  </Can>

                  <Can permission={[permissions.widgetEdit]}>
                    <SidebarLink
                      title={t('section.filters')}
                      icon={SIDEBAR_ICON.PARSING}
                      href={routes.filters}
                      pathname={asPath}
                      onClick={handleClick}
                      dataSelenium="Filters"
                    />
                  </Can>

                  <Can
                    permission={[
                      permissions.reportPurchase,
                      permissions.reportRefund,
                      permissions.reportSales,
                      permissions.reportOrder,
                      permissions.reportDelivery,
                    ]}
                  >
                    <SidebarLink
                      title={t('section.reports')}
                      icon={SIDEBAR_ICON.REPORTS}
                      href={routes.reportsDynamic}
                      root="/reports"
                      pathname={asPath}
                      onClick={handleClick}
                      dataSelenium="Reports"
                    />
                  </Can>
                  <Can permission={permissions.companyReportMarketplace}>
                    <SidebarLink
                      title={t('section.pbreports')}
                      icon={SIDEBAR_ICON.REPORTS}
                      href={routes.pbReportsOrders}
                      root="/pbreports"
                      pathname={asPath}
                      onClick={handleClick}
                      dataSelenium="PBReports"
                    />
                  </Can>
                </Styled.Menu>
              </Can>
              <Can permission={[permissions.companyUser, permissions.companyManager, permissions.crawler]}>
                <Styled.Menu>
                  <Styled.MenuTitle>{t('title.admin')}</Styled.MenuTitle>
                  <Can permission={[permissions.companyUser, permissions.companyManager]}>
                    <SidebarLink
                      title={t('section.members')}
                      icon={SIDEBAR_ICON.USERS}
                      href={membersLink}
                      root={routes.membersUsers}
                      pathname={asPath}
                      onClick={handleClick}
                      dataSelenium="Users"
                    />
                  </Can>
                  <Can permission={permissions.crawler}>
                    <SidebarLink
                      title={t('section.parsers')}
                      icon={SIDEBAR_ICON.PARSING}
                      href={routes.parsersEvents}
                      pathname={asPath}
                      onClick={handleClick}
                      dataSelenium="Parsing"
                    />
                  </Can>
                </Styled.Menu>
              </Can>
              <Can permission={permissions.accessToClients}>
                <Styled.Menu>
                  <Styled.MenuTitle>{t('title.clients')}</Styled.MenuTitle>
                  <SidebarLink
                    title={t('section.clients')}
                    icon={SIDEBAR_ICON.CLIENTS}
                    href={routes.clients}
                    pathname={asPath}
                    onClick={handleClick}
                    dataSelenium="Clients"
                  />
                </Styled.Menu>
              </Can>
            </Styled.Nav>
          </Styled.Wrapper>
          <Styled.Close onClick={handleToggleSidebarState} />
        </Styled.Container>
      </ThemeProvider>
    );
  }
}

export default Sidebar;
