/* eslint-disable */
import 'moment-timezone';
import 'moment/locale/ru';
import { attachLogger } from 'effector-logger';
import Sidebar from 'containers/Sidebar';
import moment from 'moment';
import Router from 'next/router';
import NProgress from 'nprogress';
import React from 'react';
import { NotificationContainer } from 'react-notifications';
import { YMInitializer } from 'react-yandex-metrika';
import App from 'next/app';
import { setCurrentUser } from 'entities/account';
import { accountSources } from 'entities/account';
import { createContext } from 'entities/call-context';
import { setSidebarState, setUserAgent } from 'entities/layout';
import { setBarState, setQueries, setExpandedAllRows } from 'entities/tables';
import COOKIES from 'shared/constants/COOKIES';
import routes from 'shared/helpers/routes';
import { getToken, isAuthenticated } from 'shared/lib/auth';
import { getCookie, removeServerCookie, parseCookieObject, setCookie } from 'shared/lib/cookie';
import redirect from 'shared/lib/redirect';
import api from 'shared/services/api';
import { appWithTranslation } from '../i18n';
import { wrapper } from '../store';

if (process.env.NODE_ENV === 'development') {
  attachLogger();
}

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

moment.locale('ru');
moment.tz.setDefault('Europe/Moscow');

const cookiesForLoadConfig = [
  {
    cookie: COOKIES.FILTERBARS,
    action: setBarState,
  },
  {
    cookie: COOKIES.QUERIES,
    action: setQueries,
  },
  {
    cookie: COOKIES.CALL_CONTEXT,
    action: createContext,
  },
  {
    cookie: COOKIES.ALL_ROWS_EXPANDED,
    action: setExpandedAllRows,
  },
];

class MainApp extends App {
  // @ts-ignore
  public static getInitialProps = wrapper.getInitialAppProps((store) => async (context) => {
    const pageProps: any = {};
    const isServer = typeof window === 'undefined';
    const { ctx, router } = context;

    const nextRoute =
      router.asPath.indexOf('login') === -1 ? `${routes.login}?next=${router.asPath}` : routes.login;

    if (isServer) {
      if (ctx.req) {
        store.dispatch(setUserAgent(ctx.req.headers['user-agent']));
      } else {
        // @ts-ignore
        store.dispatch(navigator.userAgent);
      }
    }

    if (isServer && isAuthenticated(ctx)) {
      try {
        const token = getToken(ctx);
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const results: any = await api.get(accountSources.me, { headers });
        setCookie(COOKIES.COMPANY, results.data.data.company.id, ctx);
        store.dispatch(setCurrentUser(results.data.data));

        const { pathname } = router;

        if (
          pathname === routes.login ||
          pathname === routes.registration ||
          pathname === routes.forgot ||
          pathname === routes.newPassword
        ) {
          return redirect(routes.root, ctx);
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          removeServerCookie(COOKIES.TOKEN, ctx);
          if (router.pathname !== routes.login) {
            return redirect(nextRoute, ctx);
          }
        }
      }

      cookiesForLoadConfig.forEach((item) => {
        const cookieData = getCookie(item.cookie, ctx);
        if (cookieData) {
          store.dispatch(item.action(parseCookieObject(cookieData)));
        }
      });

      const sidebarIsOpen = getCookie(COOKIES.SIDEBAR_IS_OPEN, ctx);

      if (sidebarIsOpen === 'true' && store.getState().layout.sidebarIsOpen === false) {
        store.dispatch(setSidebarState(true));
      }
    }

    if (
      isServer &&
      !isAuthenticated(ctx) &&
      router.pathname !== routes.login &&
      router.pathname !== routes.registration &&
      router.pathname !== routes.forgot &&
      router.pathname !== routes.newPassword
    ) {
      return redirect(nextRoute, ctx);
    }

    return {
      pageProps: {
        ...(await App.getInitialProps(context)).pageProps,
        ...pageProps,
      },
    };
  });

  render() {
    const { Component, pageProps }: any = this.props;

    return (
      <>
        {!pageProps.disableLayout && <Sidebar />}
        <Component {...pageProps} />
        <NotificationContainer />
        {process.env.YM && (
          <YMInitializer
            accounts={[+process.env.YM]}
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
            }}
          />
        )}
      </>
    );
  }
}

export default wrapper.withRedux(appWithTranslation(MainApp));
