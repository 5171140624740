import { normalize, schema } from 'normalizr';
import merge from 'lodash/merge';

const INITIAL_STATE = {
  data: {},
  ids: [],
  meta: {},
  shouldMerge: false,
  state: {
    isLoading: true,
    isLoadingMore: false,
    isFail: false,
  },
};

export const createNormalzedTableReducer =
  (actionType, reducerFunction, options = {}) =>
  (state = INITIAL_STATE, action) => {
    if (reducerFunction) {
      const newState = reducerFunction(state, action);

      if (newState) {
        return newState;
      }
    }

    switch (action.type) {
      case `${actionType}.LOAD_DATA`: {
        const { shouldMerge } = state;
        return {
          ...state,
          data: shouldMerge ? state.data : {},
          ids: shouldMerge ? state.ids : [],
          meta: shouldMerge ? state.meta : {},
          state: {
            isLoading: !shouldMerge,
            isLoadingMore: shouldMerge,
            isFail: false,
          },
        };
      }

      case `${actionType}.REFRESH_SUCCESS`:
      case `${actionType}.LOAD_DATA_SUCCESS`: {
        const { results: data, ...meta } = action.payload.data.data;
        const { shouldMerge } = state;
        const scheme = new schema.Entity('data', {}, options);
        const listScheme = new schema.Array(scheme);
        const { entities, result: ids } = normalize(data, listScheme);

        return {
          ...state,
          meta,
          data: shouldMerge ? merge(state.data, entities.data) : entities.data || {},
          ids: shouldMerge ? [...state.ids, ...ids] : ids,
          shouldMerge: false,
          state: {
            isLoading: false,
            isLoadingMore: false,
            isFail: false,
          },
        };
      }
      case `${actionType}.LOAD_DATA_FAIL`:
        return {
          ...state,
          data: {},
          ids: [],
          meta: {},
          shouldMerge: false,
          state: {
            isLoading: false,
            isLoadingMore: false,
            isFail: true,
          },
        };
      case `${actionType}.LOAD_MORE`: {
        return {
          ...state,
          shouldMerge: true,
          isLoadingMore: true,
        };
      }

      default:
        return state;
    }
  };
