import { actionTypes } from './actionTypes';
import { preordersSources } from './sources';

const loadPreorders = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: preordersSources.root,
      params,
    },
  },
});

const loadPreordersMore = () => ({
  type: actionTypes.LOAD_MORE,
});

const changePreorderExtra = (id: number | string, data: any) => ({
  type: actionTypes.CHANGE_PREORDER_EXTRA,
  payload: {
    request: {
      method: 'POST',
      url: preordersSources.extra(id),
      data,
    },
  },
});

const finishPreorder = (id: number | string) => ({
  type: actionTypes.FINISH_PREORDER,
  payload: {
    request: {
      url: preordersSources.finish(id),
      id,
    },
  },
});

const loadPreorder = (id: number | string) => ({
  type: actionTypes.LOAD_DETAIL,
  payload: {
    request: {
      url: preordersSources.detail(id),
    },
  },
});

export { loadPreorders, loadPreordersMore, changePreorderExtra, finishPreorder, loadPreorder };
