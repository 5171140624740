import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SidebarLink from './SidebarLink';
import api from 'shared/services/api';
import { orderSources } from 'entities/order';
import { SidebarLinkProps } from './SidebarLink/SidebarLink';

interface Props extends SidebarLinkProps {
  sales: any[];
}

const SidebarSales: React.FC<Props> = (props) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const loadCount = () => {
      api
        .get(orderSources.rootv2, { params: { status__in: 1 } })
        .then((response) => {
          setCount(response.data.data.count);
        })
        .catch((err) => new Error(err));
    };

    loadCount();
  }, [props.sales]);

  return <SidebarLink {...props} withNewLabel={Boolean(count)} />;
};

const mapDispatchToProps = (state) => ({
  sales: state.sales.data,
});

export default connect(mapDispatchToProps)(SidebarSales);
