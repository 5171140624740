import { actionTypes } from '../actionTypes';

const INITIAL_STATE = {
  data: null,
  counts: {},
  meta: null,
  state: {
    isLoading: false,
    isFail: false,
  },
};

export const eventsDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DETAIL:
      return {
        ...state,
        state: {
          isLoading: true,
          isFail: false,
        },
      };
    case actionTypes.LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload.data },
        state: {
          isLoading: false,
          isFail: false,
        },
      };
    case actionTypes.LOAD_DETAIL_FAIL:
      return {
        ...state,
        data: null,
        state: {
          isLoading: false,
          isFail: true,
        },
      };

    case actionTypes.CREATE_FROM_PARSER:
      return {
        ...state,
        data: action.payload.data,
      };

    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: null,
      };

    case actionTypes.CLEAR_DETAIL_DATA:
      return {
        ...state,
        data: null,
      };

    case actionTypes.LOAD_POOL_COUNT:
    case actionTypes.LOAD_ANNOUNCEMENTS_COUNT:
      return {
        ...state,
        counts: {},
      };

    case actionTypes.LOAD_ANNOUNCEMENTS_COUNT_SUCCESS:
    case actionTypes.LOAD_POOL_COUNT_SUCCESS:
      return {
        ...state,
        counts: action.payload.data,
      };

    case actionTypes.LOAD_POOL_META:
    case actionTypes.LOAD_ANNOUNCEMENTS_META:
      return {
        ...state,
        meta: null,
      };

    case actionTypes.LOAD_ANNOUNCEMENTS_META_SUCCESS:
    case actionTypes.LOAD_POOL_META_SUCCESS:
      return {
        ...state,
        meta: action.payload.data,
      };

    case actionTypes.RESET_COUNTS:
      return {
        ...state,
        counts: {},
        meta: null,
      };

    default:
      return state;
  }
};
