import { actionTypes } from './actionTypes';
import { orderSources } from 'entities/order';

export const loadPurchases = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: orderSources.root,
      params: {
        ...params,
        my_sales: true,
      },
    },
  },
});

export const loadPurchasesv2 = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: orderSources.rootv2,
      params: {
        ...params,
        my_sales: true,
      },
    },
  },
});

export const loadPurchasesMore = () => ({
  type: actionTypes.LOAD_MORE,
});

export const addNewPurchase = (data: any) => ({
  type: actionTypes.ADD_NEW,
  payload: {
    data,
  },
});
