import announcementsSource from 'shared/sources/announcements';
import { actionTypes } from './actionTypes';

const loadAnnouncement = (id: number | string) => ({
  type: actionTypes.LOAD_DETAIL,
  payload: {
    request: {
      url: announcementsSource.detail(id),
      id,
    },
  },
});

const loadRawAnnouncement = (id: number | string) => ({
  type: actionTypes.LOAD_DETAIL_RAW,
  payload: {
    request: {
      url: announcementsSource.detailRaw(id),
      id,
    },
  },
});

const editAnnouncement = (id: number | string, data: any) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'PATCH',
      url: announcementsSource.detailUpdate(id),
      data,
      id,
    },
  },
});

const selectSeat = ({ announcement, type, count, ticket, shouldClear }: any) => ({
  type: actionTypes.SELECT_SEAT,
  payload: {
    announcement,
    type,
    ticket,
    count,
    shouldClear,
  },
});

const deleteSelectedTicket = (id: number | string) => ({
  type: actionTypes.DELETE_SELECTED_TICKET,
  payload: {
    id,
  },
});

const clearSelectedTickets = () => ({
  type: actionTypes.CLEAR_SELECTED_TICKETS,
});

const loadUserAnnouncement = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: announcementsSource.listv2,
      params,
    },
  },
});

const loadMoreUserAnnouncement = () => ({
  type: actionTypes.LOAD_MORE,
});

const updateAnnouncement = (id: number | string, data: any) => ({
  type: actionTypes.UPDATE,
  payload: {
    request: {
      method: 'PATCH',
      url: announcementsSource.detail(id),
      data,
    },
    withNotification: true,
  },
});

const closeUpdateBookingModal = () => ({
  type: actionTypes.CLOSE_UPDATE_MODAL,
});

const updateSelectedTickets = (checks: any) => ({
  type: actionTypes.UPDATE_SELECTED_TICKETS,
  payload: {
    checks,
  },
});

const updateTotalPrice = (id: number | string, newPrice: number | string) => ({
  type: actionTypes.UPDATE_TICKET_PRICE,
  payload: {
    id,
    newPrice,
  },
});

const setSelectedAnnouncementsFromStorage = (data: any) => ({
  type: actionTypes.SET_SELECTED_ANNOUNCEMENT,
  payload: {
    data,
  },
});

const deleteAnnouncement = (id: number | string) => ({
  type: actionTypes.DELETE,
  payload: {
    id,
    request: {
      method: 'DELETE',
      url: announcementsSource.detail(id),
      id,
    },
  },
});

const deleteAnnouncements = (data: any) => ({
  type: actionTypes.BULK_DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: announcementsSource.bulkDelete,
      data: {
        announcements: data.ids,
      },
    },
  },
});

const updateAnnouncementsPrice = (data: any) => ({
  type: actionTypes.BULK_PRICE_UPDATE,
  payload: {
    request: {
      method: 'POST',
      url: announcementsSource.bulkPriceUpdate,
      data: {
        announcements: data.ids,
        price: data.price,
      },
      ids: data.ids,
      price: data.price,
    },
  },
});

const updateAnnouncementsStatus = (data: any) => ({
  type: actionTypes.BULK_STATUS_UPDATE,
  payload: {
    request: {
      method: 'POST',
      url: announcementsSource.bulkStatusUpdate,
      data: {
        announcements: data.ids,
        status: Number(data.status),
      },
    },
  },
});

const addAnnouncementsTickets = (id: number | string, data: any) => ({
  type: actionTypes.ADD_TICKETS,
  payload: {
    request: {
      method: 'POST',
      url: announcementsSource.addTickets(id),
      data,
    },
  },
});

const deleteAnnouncementsTickets = (id: number | string, data: any) => ({
  type: actionTypes.DELETE_TICKETS,
  payload: {
    request: {
      method: 'DELETE',
      url: announcementsSource.deleteTickets(id),
      data,
    },
  },
});

const refundAnnouncementsTickets = (id: number | string, data: any) => ({
  type: actionTypes.REFUND_TICKETS,
  payload: {
    request: {
      method: 'DELETE',
      url: announcementsSource.refundTickets(id),
      data,
    },
  },
});

const separateAnnouncement = (id: number | string, data: any) => ({
  type: actionTypes.SEPARATE_TICKETS,
  payload: {
    request: {
      method: 'POST',
      url: announcementsSource.separateTickets(id),
      data,
    },
  },
});

const loadHistory = (id: number | string, params = {}) => ({
  type: actionTypes.LOAD_HISTORY,
  payload: {
    request: {
      url: announcementsSource.history(id),
      params,
    },
  },
});

const loadPriceChangeSchedule = (id: number | string, params = {}) => ({
  type: actionTypes.LOAD_PRICE_CHANGE_SCHEDULE,
  payload: {
    request: {
      url: announcementsSource.priceChangeScheduleList(id),
      params,
    },
  },
});

const createPriceChangeSchedule = (id: number | string, data: any) => ({
  type: actionTypes.CREATE_PRICE_CHANGE_SCHEDULE,
  payload: {
    request: {
      method: 'POST',
      url: announcementsSource.priceChangeScheduleList(id),
      data,
    },
  },
});

const editPriceChangeSchedule = (id: number | string, schedule: string, data: any) => ({
  type: actionTypes.EDIT_PRICE_CHANGE_SCHEDULE,
  payload: {
    request: {
      method: 'PATCH',
      url: announcementsSource.priceChangeScheduleDetail(id, schedule),
      data,
    },
  },
});

const deletePriceChangeSchedule = (id: number | string, schedule: string) => ({
  type: actionTypes.DELETE_PRICE_CHANGE_SCHEDULE,
  payload: {
    request: {
      method: 'DELETE',
      url: announcementsSource.priceChangeScheduleDetail(id, schedule),
    },
  },
});

export {
  loadAnnouncement,
  editAnnouncement,
  selectSeat,
  deleteSelectedTicket,
  loadUserAnnouncement,
  loadMoreUserAnnouncement,
  updateAnnouncement,
  clearSelectedTickets,
  updateSelectedTickets,
  updateTotalPrice,
  setSelectedAnnouncementsFromStorage,
  deleteAnnouncement,
  deleteAnnouncements,
  updateAnnouncementsPrice,
  updateAnnouncementsStatus,
  deleteAnnouncementsTickets,
  refundAnnouncementsTickets,
  separateAnnouncement,
  addAnnouncementsTickets,
  loadRawAnnouncement,
  closeUpdateBookingModal,
  loadHistory,
  loadPriceChangeSchedule,
  createPriceChangeSchedule,
  editPriceChangeSchedule,
  deletePriceChangeSchedule,
};
