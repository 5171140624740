export const orderSources = {
  root: 'order',
  rootv2: 'order/v2',
  bulkReject: 'order/bulk-reject',
  bulkAssignMe: 'order/bulk-assign-me',
  bulkAssignManager: 'order/bulk-assign-manager',
  filtersSites: 'order/filters/sites',
  filtersBrokers: 'order/filters/brokers',
  rejectReasonsList: 'order/reject-reasons-list',
  detail: (id: number | string) => `order/${id}`,
  comments: (id: number | string) => `order/${id}/comment`,
  addComment: (id: number | string) => `order/${id}/comment`,
  addCommentAttachment: (id: number | string) => `order/${id}/comment-attachment`,
  loadCommentAttachment: (id: number | string, attachmentId: number | string) =>
    `order/${id}/${attachmentId}/comment-proxy`,
  related: (id: number | string) => `order/${id}/related`,
  cross: (id: number | string) => `order/${id}/cross`,
  deliverySuccess: (id: number | string) => `order/${id}/success-delivery`,
  delivery: (id: number | string) => `order/${id}/delivery`,
  emailDelivery: (id: number | string) => `order/${id}/delivery-by-email`,
  payment: (id: number | string) => `order/${id}/payment`,
  paymentSuccess: (id: number | string) => `order/${id}/success-payment`,
  finish: (id: number | string) => `order/${id}/finish`,
  reject: (id: number | string) => `order/${id}/reject`,
  assign: (id: number | string) => `order/${id}/assign`,
  assignToMe: (id: number | string) => `order/${id}/assign-to-me`,
  discount: (id: number | string) => `order/${id}/set-discount`,
  deleteTickets: (id: number | string) => `order/${id}/pure-tickets`,
  deleteTicketsFromPool: (id: number | string) => `order/${id}/delete-tickets`,
  addTicketsFromPool: (id: number | string) => `order/${id}/add-tickets-from-pool`,
  refund: (id: number | string) => `order/${id}/refund`,
  externalId: (id: number | string) => `order/${id}/set-external-id`,
  updateCustomerInfo: (id: number | string) => `order/${id}/update-customer-info`,
  operatorStake: (id: number | string) => `order/${id}/stake`,
  outlay: (id: number | string) => `order/${id}/outlay`,
  roistat: (id: number | string) => `order/${id}/change-roistat-field`,
  confirmReject: (id: number | string) => `order/${id}/confirm-reject`,
  confirmRefund: (id: number | string) => `order/${id}/confirm-refund`,
  returnOrderOnSale: (id: number | string) => `order/${id}/turn-time`,
  createTickets: (id: number | string) => `order/${id}/create-ticket-for-order`,
  history: (id: number | string) => `order/${id}/data-history`,
  setTicketSearch: (id: number | string) => `order/${id}/ticket-search`,
  saleTickets: (id: number | string) => `order/${id}/sale-tickets`,
  downloadReport: (id: number | string) => `order/${id}/order-report`,
  filtersBroker: (id: number | string) => `order/filters/brokers/${id}`,
  createInvoice: (id: number | string) => `order/${id}/create-invoice`,
  sendInvoice: (id: number | string) => `order/${id}/send-invoice`,
  viewHistory: (id: number | string) => `order/${id}/view-history`,
  setSite: (id: number | string) => `order/${id}/extra/site-url`,
  provider: (id: number | string) => `order/${id}/payment-provider`,
  createNotification: (id: number | string) => `order/${id}/create-notification`,
  generateTickets: (id: number | string) => `order/${id}/generate-tickets`,
  refundRequest: (id: number | string) => `order/${id}/update`,
};
